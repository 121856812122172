import React, { FC, PropsWithChildren } from "react";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

type INodeLoadingProps = PropsWithChildren<{
  loading: boolean;
}>;

const NodeLoading: FC<INodeLoadingProps> = ({ loading, children }) => {
  const { t } = useTranslation();

  if (loading) return <div className={styles.loading}>{t("LOADING")}</div>;
  return <>{children}</>;
};

export default NodeLoading;
