import React, { SVGProps } from 'react';

const LinkedIn = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      fill="none"
      viewBox="0 0 21 20"
      data-color="emphasize"
      {...props}>
      <path
        fill="currentColor"
        d="M15.772 1.587h2.811l-6.141 7.02 7.225 9.552h-5.658l-4.43-5.794-5.07 5.794H1.695l6.569-7.509-6.932-9.063h5.801l4.006 5.295 4.632-5.295zm-.987 14.889h1.558L6.288 3.18H4.616l10.17 13.295z"></path>
    </svg>
  );
};

export default LinkedIn;
