import * as React from "react";
import { createPortal } from "react-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { MyID } from "@greyhole/myid-connect/myid_connect";
import { MeReply } from "@greyhole/myid-connect/myid_pb";

import Avatar from "assets/images/avatar.png";
import styles from "./styles.module.scss";
import { dragonTransport, useClient } from "transport";
import { useSignOut } from "hooks/useSignOut";

interface ILoggedProps {}

const Logged: React.FunctionComponent<ILoggedProps> = (props) => {
  const myIdClient = useClient(MyID, dragonTransport("bison"));
  const { data, isLoading } = useQuery<MeReply>({
    queryKey: myIdClient.me.getQueryKey(),
  });
  const { logOut } = useSignOut();

  const avatarUrl = React.useMemo(() => {
    if (!data?.user?.metadata) return Avatar;
    try {
      const metadata = JSON.parse(data.user.metadata);
      return metadata.avatar || Avatar;
    } catch (error) {
      return Avatar;
    }
  }, [data?.user?.metadata]);

  const openMenu = () => {
    createPortal(
      <p>This child is placed in the document body.</p>,
      document.body
    );
  };

  if (isLoading) return <>...</>;

  return (
    <div className={styles.logged}>
      {/* <div className={styles.mLogged}>
        <i className="fa fa-bell"></i>
      </div>
      <div className={styles.mLogged}>
        <i className="fa fa-messages"></i>
      </div> */}
      <div onClick={openMenu} className={`${styles.avatar} ${styles.mLogged}`}>
        <img src={avatarUrl} alt="Avatar" />
        <i className="fa fa-chevron-down"></i>
      </div>
      <div className={styles.logoutMobile}>
        <img src={avatarUrl} alt="Avatar" />
        <i className="fa fa-right-from-bracket" onClick={logOut}></i>
      </div>
    </div>
  );
};

export default Logged;
