import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Game } from "@marketplace/myplace-connect/myplace_pb";

import GAME_IMAGE from "assets/images/game.png";
import { getResource } from "utils";
import MiniInfo from "./MiniInfo";
import styles from "./styles.module.scss";

interface IGameItemProps {
  game: Game;
}

const GameItem: React.FunctionComponent<IGameItemProps> = ({ game }) => {
  const { t } = useTranslation();

  const gameUrl = `/games/${game.slug}/play`;

  return (
    <div className={`col-4 ${styles.colMobile}`}>
      <div className={`${styles.game}`}>
        <div className={styles.thumbnail}>
          <div className={styles.img}>
            <img
              src={getResource(game.image320x468 || GAME_IMAGE)}
              alt="game"
            />
          </div>

          <div className={styles.info}>
            <h3 className={styles.title}>{game?.name}</h3>
            <p className={styles.desc}>{game?.description}</p>

            <div className={styles.bottomBox}>
              <div className={styles.info1}>
                <div className="row ">
                  <MiniInfo title="MEDIUM" subTitle={t("VOLATILITY")} />
                  <MiniInfo title="92.2%" subTitle={t("RTP")} />
                  <MiniInfo title="21" subTitle={t("LANGUAGES")} />
                </div>
              </div>

              <div className={styles.info2}>
                <div className="row">
                  <MiniInfo title="x20200" subTitle={t("MAXIMUM_WIN")} />
                  <MiniInfo
                    title={
                      <div className={styles.orientation}>
                        {/* {[].includes("ORIENTATION_VERTICAL") && (
                          <img src={MOBILE_IMAGE} alt="Mobile" />
                        )}
                        {[].includes("ORIENTATION_HORIZONTAL") && (
                          <img src={DESKTOP_IMAGE} alt="Desktop" />
                        )} */}
                      </div>
                    }
                    subTitle={t("ORIENTATION")}
                  />
                  <MiniInfo
                    title={
                      <i className={`fa fa-shield-check ${styles.jpOn}`}></i>
                    }
                    subTitle={t("JACKPOT")}
                  />
                </div>
              </div>

              <div className={styles.playBtn}>
                <Link to={gameUrl}>{t("PLAY_NOW")}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameItem;
