import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import screenfull from "screenfull";
import { Link } from "react-router-dom";
import { Game } from "@marketplace/myplace-connect/myplace_pb";

import Breadcrumbs from "components/Breadcrumbs";
import { CURRENCY_DEFAULT } from "utils";
import styles from "./styles.module.scss";
import BackgroundCover from "./BackgroundCover";
import { getToken } from "transport";
import { useMobile } from "hooks/useMobile";
import ControlBar from "./ControlBar";
import { useCreateSessionMyAdapter } from "hooks/useCreateSessionMyAdapter";
import { useCurrency } from "hooks/useCurrency";
import { getRootGameId } from "./const";

type INauGameIframeProps = PropsWithChildren<{
  loading: boolean;
  game?: Game;
}>;

const NauGameIframe: FC<INauGameIframeProps> = ({ game, loading }) => {
  const { i18n, t } = useTranslation();
  const langKey = i18n.language;
  const { isMobile } = useMobile();
  const gameContainer = useRef<HTMLDivElement>(null);
  const gameBoxRef = useRef<HTMLDivElement>(null);
  const [sizeGame, setSizeGame] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });

  const [random, setRandom] = useState<number>(0);
  const [sessionExpired, setSessionExpired] = useState(false);
  const { mutateAsync: createSession, data } = useCreateSessionMyAdapter();
  const { getCurrency } = useCurrency();

  const gameLink = React.useMemo(() => {
    if (!data?.token) return "";
    if (!game?.url) return "";
    let paths = [];
    let origin: string = game.url;
    if (origin.charAt(origin.length - 1) === "/") {
      paths.push(origin.slice(0, -1));
    } else {
      paths.push(origin);
    }

    if (game.version) {
      paths.push(game.version);
    }

    const params = new URLSearchParams();
    params.append("tenant_id", window.TENANT_ID);
    params.append("tenant_player_token", data?.token);
    params.append("language", langKey);
    params.append("currency", getCurrency() || CURRENCY_DEFAULT);

    paths.push(`?${params.toString()}`);

    return paths.join("/");
  }, [game?.url, data?.token, langKey, getCurrency]);

  const parseJwt = (token?: string) => {
    if (!token) return;
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const onLoadIframe = useCallback(() => {
    const i = parseJwt(data?.token);

    if (!i || !i?.exp) {
      setSessionExpired(true);
      return createSession({ gameId: getRootGameId(game?.gameId) }).finally(
        () => {
          setSessionExpired(false);
        }
      );
    }
    const expMilliseconds = i.exp * 1000;
    if (Date.now() > expMilliseconds) {
      setSessionExpired(true);
      return createSession({ gameId: getRootGameId(game?.gameId) }).finally(
        () => {
          setSessionExpired(false);
        }
      );
    }
  }, [data?.token]);

  useEffect(() => {
    if (!game?.gameId) return;
    createSession({ gameId: getRootGameId(game?.gameId) });
  }, [game?.gameId]);

  const expandScreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle(gameBoxRef?.current as any);
    }
  };

  const reload = () => {
    setRandom(Date.now());
  };

  const rotate = () => {
    if (isMobile) {
      if (sizeGame.width > sizeGame.height) {
        const clientHeightOver = window.innerHeight - 200;

        setSizeGame({
          height: clientHeightOver,
          width: sizeGame.width,
        });
      } else {
        setSizeGame({
          width: sizeGame.width,
          height: (sizeGame.width * 9) / 16,
        });
      }

      return;
    }

    if (sizeGame.width > sizeGame.height) {
      setSizeGame({
        width: (sizeGame.height * 9) / 16,
        height: sizeGame.height,
      });
    } else {
      setSizeGame({
        width: (sizeGame.height * 16) / 9,
        height: sizeGame.height,
      });
    }
  };

  useLayoutEffect(() => {
    initGameContainer();
  }, []);

  const initGameContainer = () => {
    let clientWidth = gameContainer.current?.clientWidth || 0;
    let clientHeight = window.innerHeight - 200;

    if (clientWidth > clientHeight) {
      clientHeight = (clientWidth * 9) / 16;
    } else {
      const clientHeightTemp = (clientWidth * 16) / 9;
      clientHeight =
        clientHeightTemp > clientHeight ? clientHeight : clientHeightTemp;
    }

    if (!!gameBoxRef?.current?.style) {
      gameBoxRef.current.style.width = `${clientWidth}px`;
      gameBoxRef.current.style.height = `${clientHeight}px`;
    }

    setSizeGame({
      width: clientWidth,
      height: clientHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", initGameContainer);
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  const iframeGame = useMemo(() => {
    // if (gameNotFound) return <div>{t("GAME_NOT_FOUND")}</div>;

    if (game?.status === "GAME_STATUS_MAINTENANCE") {
      return <div>{t("GAME_MAINTENANCE")}</div>;
    }

    return gameLink ? (
      <iframe
        src={gameLink}
        title="Game"
        className={styles.iframeBox}
        id="iframeGame"
        allowFullScreen
        key={random}
        onLoad={onLoadIframe}
      ></iframe>
    ) : (
      <div>{t("LOADING")}...</div>
    );
  }, [gameLink, random, game?.status]);

  return (
    <>
      <Breadcrumbs checkPoint={loading ? "..." : game?.name || "-"} />
      <div ref={gameContainer}>
        <div
          className={`relative hidden ${styles.wrapperGameBox}`}
          ref={gameBoxRef}
        >
          <BackgroundCover game={game} />
          <div
            className={`${styles.gameBox} ${styles.horizontalFullScreen}`}
            style={{
              height: sizeGame.height || "auto",
              width: sizeGame.width || "auto",
            }}
          >
            {!getToken() ? (
              <div className={styles.loginToPlayGame}>
                <Link to={"/sign-in"}>{t("LOGIN")}</Link>{" "}
                {t("PLAY_THIS_GAME").toLowerCase()}
              </div>
            ) : sessionExpired ? (
              <div>Creating a new session...</div>
            ) : (
              iframeGame
            )}
          </div>
        </div>
        <ControlBar
          expandScreen={expandScreen}
          reload={reload}
          rotate={rotate}
          isMobile={sizeGame.width < sizeGame.height}
        />
      </div>
    </>
  );
};

export default NauGameIframe;
