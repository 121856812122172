import React, { SVGProps } from 'react';

const LinkedIn = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      fill="none"
      viewBox="0 0 21 20"
      data-color="emphasize"
      {...props}>
      <g clipPath="url(#clip0_703_80888)">
        <path
          fill="currentColor"
          d="M19.02 0H1.977C1.16 0 .5.645.5 1.441v17.114C.5 19.352 1.16 20 1.977 20H19.02c.816 0 1.48-.648 1.48-1.441V1.44C20.5.645 19.836 0 19.02 0zM6.434 17.043h-2.97V7.496h2.97v9.547zM4.949 6.195a1.72 1.72 0 11-.006-3.439 1.72 1.72 0 01.006 3.44zm12.594 10.848h-2.965v-4.64c0-1.106-.02-2.532-1.543-2.532-1.543 0-1.777 1.207-1.777 2.453v4.719H8.297V7.496h2.844v1.305h.039c.394-.75 1.363-1.543 2.804-1.543 3.004 0 3.559 1.976 3.559 4.547v5.238z"></path>
      </g>
      <defs>
        <clipPath id="clip0_703_80888">
          <path fill="#fff" d="M0 0H20V20H0z" transform="translate(.5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkedIn;
