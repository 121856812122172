import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import Logo from "assets/images/logo.svg";
import { localStorageLng } from "utils/lang";
import Language, { mapKeyToLang } from "./Language";
import Profile from "./Profile";
import styles from "./styles.module.scss";
import DialogMenu from "./DialogMenu";
import Currency from "./Currency";

interface IHeaderProps {
  show?: boolean;
}

export type LangType = {
  title: string;
  icon: React.ReactNode;
};

const Header: FC<IHeaderProps> = ({ show }) => {
  const { i18n, t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [currencyVisible, setCurrencyVisible] = useState<boolean>(false);

  useEffect(() => {
    const elt = document.getElementById("header");
    if (!elt) return;

    document.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        elt.classList.add(styles.show);
      } else {
        elt.classList.remove(styles.show);
      }
    });

    return () => {
      document.removeEventListener("scroll", () => {});
    };
  }, []);

  const changeLang = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const onOpen = () => {
    setVisible((v) => !v);
  };

  const onHide = () => {
    setVisible(false);
  };

  return (
    <>
      <div
        className={`${styles.header} ${show && styles.forceShow}`}
        id="header"
      >
        <div className={styles.box}>
          <div className={styles.menu} onClick={onOpen}>
            <i>
              <FontAwesomeIcon icon={faBars} />
            </i>
            <span>{t("MENU")}</span>
          </div>
          <div className={styles.logo}>
            <Link to="/">
              <img src={Logo} alt="Logo" />
            </Link>
            <span className={styles.version}>v1.0.23</span>
          </div>
          <div className={styles.rightContent}>
            <Currency
              currencyVisible={currencyVisible}
              toggleCurrency={setCurrencyVisible}
            />
            <Language />
            <Profile />
          </div>
        </div>
      </div>

      <DialogMenu
        onHide={onHide}
        visible={visible}
        onCurrencyToggle={setCurrencyVisible}
      />
    </>
  );
};

export default Header;
