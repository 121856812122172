import * as React from "react";
import { UseFormRegisterReturn } from "react-hook-form";

import styles from "./styles.module.scss";
import { mapKeyToCurrency } from "utils/currency";

type ISelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  label: string;
  placeholder: string;
  register?: UseFormRegisterReturn;
};

const Select: React.FunctionComponent<ISelectProps> = ({
  label,
  register,
  ...rest
}) => {
  return (
    <div className={styles.formGroup}>
      <div className={styles.label}>{label}</div>
      <div className={styles.input}>
        <select {...rest} {...register}>
          {Object.entries(mapKeyToCurrency).map(([value, data]) => {
            return (
              <option value={value} key={data.shorthand}>
                {data.title} - {data.shorthand}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default Select;
