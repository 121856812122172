import * as React from "react";
import { useTranslation } from "react-i18next";
import ContentLoader from "react-content-loader";
import { useSearchParams } from "react-router-dom";
import { MyPlace } from "@marketplace/myplace-connect/myplace_connect";
import { useQuery } from "@tanstack/react-query";

import Container from "components/Container";
import { Category } from "components/Sidebar/const";
import ListGameLayout from "layout/ListGameLayout";
import { withMainLayout } from "layout/withMainLayout";
import GameItem from "./Game";
import { FilterConfig } from "./const";
import styles from "./styles.module.scss";
import Title from "./Title";
import { getMooseTransport, useClient } from "transport";

interface IHomeProps {}

const Home: React.FunctionComponent<IHomeProps> = (props) => {
  const { t, i18n } = useTranslation();
  const [query] = useSearchParams();

  const categoryId = query.get(FilterConfig.CATEGORIES) || Category.ALL_GAME;
  const search = query.get(FilterConfig.SEARCH_GAME) || "";
  const myPlaceClient = useClient(MyPlace);

  const { data, isLoading: loading } = useQuery(
    myPlaceClient.listGames.createUseQueryOptions(
      {
        lang: i18n.language,
        tenantId: window.TENANT_ID,
      },
      { transport: getMooseTransport() }
    )
  );

  console.log("games", data);

  const dataTrue = React.useMemo(() => {
    if (loading || !data?.games?.length) return [];
    let games = data.games;
    if (categoryId !== Category.ALL_GAME) {
      games = games.filter((g) => g.categories.includes(categoryId));
    }

    if (!search) return games;
    return games.filter((d) => {
      if (d.gameId.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
        return true;

      return d.name?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase());
    });
  }, [search, loading, data, i18n.language, categoryId]);

  const loadingDom = React.useMemo(() => {
    return Array(3)
      .fill("")
      .map((_, i) => {
        return (
          <div className="col-4" key={i}>
            <div>
              <ContentLoader
                width="100%"
                height="100%"
                viewBox="0 0 388 600"
                backgroundColor="#f0f0f0"
                foregroundColor="#dedede"
              >
                <rect x="0" y="0" rx="10" ry="10" width="388" height="600" />
              </ContentLoader>
            </div>
          </div>
        );
      });
  }, []);

  return (
    <>
      {/* <Banner /> */}
      <div className={styles.homeBox}>
        <Container className="mbi-88">
          <ListGameLayout>
            <Title categoryId={categoryId} search={search} />
            <div className="row">
              {loading
                ? loadingDom
                : dataTrue?.map((g) => {
                    return <GameItem game={g} key={g.gameId} />;
                  })}
            </div>

            {!loading && (
              <div className={styles.moreGameBtn}>
                <button>
                  <span>
                    {t("MORE_GAMES")} ({dataTrue?.length || 0}/
                    {dataTrue?.length || 0})
                  </span>
                </button>
              </div>
            )}
          </ListGameLayout>
        </Container>
      </div>
    </>
  );
};

export default withMainLayout(Home, { headerShow: true });
