import React, { FC, PropsWithChildren, useState } from "react";

import styles from "./styles.module.scss";
import { mapKeyToCurrency } from "utils/currency";

type IDialogCurrencyProps = PropsWithChildren<{
  currency: string;
  onClose: () => void;
  onConfirm: () => void;
}>;

const DialogCurrency: FC<IDialogCurrencyProps> = ({
  currency,
  onClose,
  onConfirm,
}) => {
  const [loading, setLoading] = useState(false);
  const confirm = () => {
    setLoading(true);
    onConfirm();
  };

  return (
    <div className={styles.dialog}>
      <div className={styles.box}>
        <div className={styles.body}>
          <div className={styles.title}>Switch Currency</div>
          <div className={styles.content}>
            <div className={styles.msg}>
              The current account will be logged out and a new account will be
              created with the currency unit:{" "}
              <span className={styles.switchCurrency}>
                {mapKeyToCurrency[currency]?.shorthand || "VND"}&nbsp;(
                <strong>{mapKeyToCurrency[currency]?.unit}</strong>)
              </span>
            </div>
            <div className={styles.buttons}>
              <button className={styles.cancel} onClick={onClose}>
                Cancel
              </button>
              <button className={styles.confirm} onClick={confirm}>
                {loading ? "Logging out ..." : "Logout and Register"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.overlay} onClick={onClose}></div>
    </div>
  );
};

export default DialogCurrency;
