import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";
import { LangType } from ".";

type ILanguageProps = PropsWithChildren<{}>;

const EnIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 513 342"
      className="rounded-[0.219rem]"
    >
      <path fill="#FFF" d="M0 0h513v342H0z"></path>
      <path
        d="M0 0h513v26.3H0zm0 52.6h513v26.3H0zm0 52.6h513v26.3H0zm0 52.6h513v26.3H0zm0 52.7h513v26.3H0zm0 52.6h513v26.3H0zm0 52.6h513V342H0z"
        fill="#D80027"
      ></path>
      <path fill="#2E52B2" d="M0 0h256.5v184.1H0z"></path>
      <path
        d="m47.8 138.9-4-12.8-4.4 12.8H26.2l10.7 7.7-4 12.8 10.9-7.9 10.6 7.9-4.1-12.8 10.9-7.7zm56.3 0-4.1-12.8-4.2 12.8H82.6l10.7 7.7-4 12.8 10.7-7.9 10.8 7.9-4-12.8 10.7-7.7zm56.5 0-4.3-12.8-4 12.8h-13.5l11 7.7-4.2 12.8 10.7-7.9 11 7.9-4.2-12.8 10.7-7.7zm56.2 0-4-12.8-4.2 12.8h-13.3l10.8 7.7-4 12.8 10.7-7.9 10.8 7.9-4.3-12.8 11-7.7zM100 75.3l-4.2 12.8H82.6L93.3 96l-4 12.6 10.7-7.8 10.8 7.8-4-12.6 10.7-7.9h-13.4zm-56.2 0-4.4 12.8H26.2L36.9 96l-4 12.6 10.9-7.8 10.6 7.8L50.3 96l10.9-7.9H47.8zm112.5 0-4 12.8h-13.5l11 7.9-4.2 12.6 10.7-7.8 11 7.8-4.2-12.6 10.7-7.9h-13.2zm56.5 0-4.2 12.8h-13.3l10.8 7.9-4 12.6 10.7-7.8 10.8 7.8-4.3-12.6 11-7.9h-13.5zm-169-50.6-4.4 12.6H26.2l10.7 7.9-4 12.7L43.8 50l10.6 7.9-4.1-12.7 10.9-7.9H47.8zm56.2 0-4.2 12.6H82.6l10.7 7.9-4 12.7L100 50l10.8 7.9-4-12.7 10.7-7.9h-13.4zm56.3 0-4 12.6h-13.5l11 7.9-4.2 12.7 10.7-7.9 11 7.9-4.2-12.7 10.7-7.9h-13.2zm56.5 0-4.2 12.6h-13.3l10.8 7.9-4 12.7 10.7-7.9 10.8 7.9-4.3-12.7 11-7.9h-13.5z"
        fill="#FFF"
      ></path>
    </svg>
  );
};

const ViIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 85.333 512 341.333"
      className="rounded-[0.219rem]"
    >
      <path fill="#D80027" d="M196.641 85.337H0v341.326h512V85.337z"></path>
      <path
        fill="#FFDA44"
        d="m256 157.279 22.663 69.747H352l-59.332 43.106 22.664 69.749L256 296.774l-59.332 43.107 22.664-69.749L160 227.026h73.337z"
      ></path>
    </svg>
  );
};

export const mapKeyToLang: Record<
  string,
  { title: string; icon: React.ReactNode }
> = {
  en: {
    title: "English",
    icon: <EnIcon />,
  },
  vi: {
    title: "Việt Nam",
    icon: <ViIcon />,
  },
};

const Language: FC<ILanguageProps> = () => {
  const { i18n } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);

  const changeLang = (lang: string) => {
    setVisible(false);
    i18n.changeLanguage(lang);
  };

  const open = () => {
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  return (
    <>
      <div className={styles.langContainer}>
        <div className={styles.langTitle} onClick={open}>
          {mapKeyToLang[i18n?.language || "en"].icon}
          <i
            className={`fa ${visible ? "fa-chevron-up" : "fa-chevron-down"}`}
          ></i>
        </div>
        <div className={`${styles.langChoice} ${visible && styles.active}`}>
          <ul>
            {Object.entries(mapKeyToLang).map(([lang, info]) => {
              return (
                <li
                  key={lang}
                  onClick={() => changeLang(lang)}
                  className={lang === i18n?.language ? styles.active : ""}
                >
                  {info.icon}
                  <span>{info.title}</span>
                </li>
              );
            })}
          </ul>
          <div className={styles.overlay} onClick={close}></div>
        </div>
      </div>
    </>
  );
};

export default Language;
