import { Code, ConnectError } from "@connectrpc/connect";
import { MyID } from "@greyhole/myid-connect/myid_connect";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import { useCallback, useRef } from "react";
import { toast } from "react-toastify";
import { dragonTransport, getAccessTokenTransport, useClient } from "transport";

export const useAuth = () => {
  const { t } = i18next;
  const client = useQueryClient();
  const myIdClient = useClient(MyID);
  const { mutateAsync } = useMutation(
    myIdClient.me.createUseMutationOptions({
      transport: dragonTransport("bison"),
    })
  );

  const { mutateAsync: createAccessToken } = useMutation(
    myIdClient.createAccessToken.createUseMutationOptions({
      transport: getAccessTokenTransport("bison"),
    })
  );

  const getMe = useCallback(() => {
    if (!localStorage.getItem("token")) return;
    mutateAsync({})
      .then((d) => {
        client.setQueryData(myIdClient.me.getQueryKey(), d);
      })
      .catch(async (e: ConnectError) => {
        if (e.code !== Code.Unauthenticated) return;
        const idToken = localStorage.getItem("idToken");
        if (!idToken) {
          localStorage.removeItem("token");
          localStorage.removeItem("idToken");
          return;
        }
        try {
          const res = await createAccessToken({ idToken });
          localStorage.setItem("token", res.tokenInfo?.accessToken || "");
          localStorage.setItem("idToken", res.tokenInfo?.idToken || "");
          getMe();
        } catch (error) {
          localStorage.removeItem("token");
          localStorage.removeItem("idToken");
        }
      });
  }, []);

  return { getMe };
};
