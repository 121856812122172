import Footer from "components/Footer";
import Header from "components/Header";
import { useEffect } from "react";

type MainLayoutProps = {
  headerShow?: boolean;
};

export const withMainLayout =
  (WrapperComponent: any, layoutProps?: MainLayoutProps) => (props: any) => {
    useEffect(() => {
      window.scrollTo({ behavior: "smooth", top: 0 });
    }, []);

    return (
      <>
        <Header show={layoutProps?.headerShow} />
        <WrapperComponent {...props} />
        <Footer />
      </>
    );
  };
