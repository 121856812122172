import React, { FC, PropsWithChildren } from "react";

import styles from "./styles.module.scss";
import { Facebook, Instagram, LinkedIn, XSocial, Youtube } from "../icons";

type ISocialProps = PropsWithChildren<{}>;

const SOCIALS = [
  {
    title: "Facebook",
    icon: <Facebook width={20} height={20} />,
    link: "https://www.facebook.com/nautilusgamesofficial/",
  },
  {
    title: "Instagram",
    icon: <Instagram width={20} height={20} />,
    link: "https://www.instagram.com/nautilusgamesofficial/",
  },
  {
    title: "LinkedIn",
    icon: <LinkedIn width={20} height={20} />,
    link: "https://www.linkedin.com/company/nautilusgamesprovider",
  },
  {
    title: "X",
    icon: <XSocial width={20} height={20} />,
    link: "https://twitter.com/nautilushub",
  },
  {
    title: "Youtube",
    icon: <Youtube width={26} height={26} />,
    link: "https://www.youtube.com/@NautilusGamesOfficial",
    className: styles.youtubeIcon,
  },
];

const Socials: FC<ISocialProps> = (props) => {
  return (
    <div className={styles.socials}>
      <ul>
        {SOCIALS.map((s) => {
          return (
            <li key={s.title}>
              <a href={s.link} className={s.className} target="_blank">
                {s.icon}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Socials;
