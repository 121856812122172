import * as React from "react";

interface ISlotIconProps {}

const SlotIcon: React.FunctionComponent<ISlotIconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.49054 2.68298C3.56467 2.57986 3.66229 2.49588 3.77532 2.43797C3.88835 2.38007 4.01354 2.3499 4.14054 2.34998H15.8595C15.9865 2.3499 16.1117 2.38007 16.2248 2.43797C16.3378 2.49588 16.4354 2.57986 16.5095 2.68298L19.7845 7.24998C19.8951 7.40404 19.9474 7.59238 19.932 7.7814C19.9166 7.97042 19.8346 8.14783 19.7005 8.28198L10.5665 17.416C10.4922 17.4904 10.404 17.5494 10.3069 17.5896C10.2098 17.6299 10.1057 17.6506 10.0005 17.6506C9.89541 17.6506 9.79131 17.6299 9.69419 17.5896C9.59707 17.5494 9.50884 17.4904 9.43454 17.416L0.29954 8.28198C0.165488 8.14783 0.0834533 7.97042 0.0680678 7.7814C0.0526824 7.59238 0.104947 7.40404 0.21554 7.24998L3.49054 2.68298ZM4.55054 3.94998L2.63954 6.61498H6.32254L7.43754 3.94998H4.55054ZM9.17054 3.94998L8.05754 6.61498H11.8995L10.7835 3.94998H9.17154H9.17054ZM12.5175 3.94998L13.6325 6.61498H17.3595L15.4475 3.94998H12.5175ZM17.5025 8.21498H13.7105L11.7215 13.997L17.5025 8.21498ZM9.97754 14.15L12.0185 8.21598H7.93654L9.97754 14.15ZM6.24454 8.21498H2.49554L8.21054 13.93L6.24454 8.21498Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SlotIcon;
