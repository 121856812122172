import { MyID } from "@greyhole/myid-connect/myid_connect";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { dragonTransport, useClient } from "transport";
import { generateRandomString } from "utils";
import { useAuth } from "./useAuth";

const isAutoLogin = process.env.REACT_APP_AUTO_LOGIN === "true";

export const useSignUpAndSignIn = () => {
  const { getMe } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const myIdClient = useClient(MyID, dragonTransport("bison"));
  const { mutateAsync: signUp, isPending } = useMutation(
    myIdClient.signUpV2.createUseMutationOptions()
  );

  const autoSignUpAndSignIn = async () => {
    if (!isAutoLogin || localStorage.getItem("token")) return setLoading(false);
    setLoading(true);
    try {
      const password = generateRandomString(12);
      const { tokenInfo } = await signUp({
        credential: {
          case: "myId",
          value: {
            username: `at_${generateRandomString(8, "non-uppercase")}`,
            password: password,
            confirmPassword: password,
          },
        },
        deviceId: generateRandomString(15),
        currency: "usd",
      });

      if (tokenInfo?.accessToken) {
        localStorage.setItem("token", tokenInfo.accessToken);
        localStorage.setItem("idToken", tokenInfo.idToken);
        setTimeout(() => getMe(), 1000);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return { loading, autoSignUpAndSignIn };
};
