import * as React from "react";
import { useTranslation } from "react-i18next";

import Logo from "assets/images/logo.svg";
import Container from "components/Container";
import Social from "./Social";
import { MENU_FOOTER } from "./const";
import styles from "./styles.module.scss";
import Socials from "components/Socials";

interface IFooterProps {}

const Footer: React.FunctionComponent<IFooterProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.footer}>
      <Container>
        <div className={styles.top}>
          <div className={styles.logo}>
            <img src={Logo} alt="Logo" />
            {/* <h4>Madison Buildings, Midtown, Queensway</h4> */}
            {/* <Social /> */}
            <Socials />
          </div>

          <div className={styles.menuMap}>
            <div className={styles.menuSplit}>
              {MENU_FOOTER.map((m) => {
                return (
                  <div key={m.title} className={styles.menuContainer}>
                    <div className={styles.item}>
                      <h3>{t(m.title).toUpperCase()}</h3>
                      <ul>
                        {m.menus.map((mm) => {
                          return (
                            <li key={mm.title}>
                              <a href={mm.link} target="_blank">
                                {t(mm.title)}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>

                    <div className={styles.expandIconMobile}>
                      <i className="fa fa-plus"></i>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.socialMobile}>
            <Social />
          </div>
          <span>COPYRIGHT © {new Date().getFullYear()} NAUTILUS™ ALL RIGHTS RESERVED.</span>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
