const HOME_PAGE_URL = process.env.REACT_APP_HOME_PAGE || "#";
const SIGMA_2024_URL = process.env.REACT_APP_SIGMA_2024 || "#";

export const MENU_FOOTER = [
  {
    title: "ABOUT_US",
    menus: [
      {
        link: HOME_PAGE_URL,
        title: "COMPANY",
      },
    ],
  },
  {
    title: "EVENTS",
    menus: [
      {
        link: SIGMA_2024_URL,
        title: "SiGMA 2024",
      },
    ],
  },
];
