import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { MeReply } from "@greyhole/myid-connect/myid_pb";
import { MyID } from "@greyhole/myid-connect/myid_connect";

import { dragonTransport, useClient } from "transport";
import { CURRENCY_DEFAULT, CURRENCY_PARAM, parseStringToObject } from "utils";

export const useCurrency = () => {
  const queryClient = useQueryClient();
  const [query] = useSearchParams();
  const myIdClient = useClient(MyID, dragonTransport("bison"));

  const meReply = queryClient.getQueryData<MeReply>(
    myIdClient.me.getQueryKey()
  );

  const currencyParam = query.get(CURRENCY_PARAM) || "";
  const getCurrency = useCallback(() => {
    if (currencyParam) return currencyParam;
    const metadata = parseStringToObject(meReply?.user?.metadata);
    return metadata?.currency || CURRENCY_DEFAULT;
  }, [currencyParam,meReply?.user?.metadata]);

  return { getCurrency };
};
