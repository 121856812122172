import * as React from "react";

import styles from "./styles.module.scss";

interface IMiniInfoProps {
  title: React.ReactNode;
  subTitle: string;
}

const MiniInfo: React.FunctionComponent<IMiniInfoProps> = ({
  title,
  subTitle,
}) => {
  return (
    <div className={`${styles.miniInfo} col-4`}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subTitle}>{subTitle}</div>
    </div>
  );
};

export default MiniInfo;
