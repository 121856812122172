import * as React from "react";

import Logo from "assets/images/logo.svg";
import styles from './styles.module.scss';

interface ILogoLoadingProps {}

const LogoLoading: React.FunctionComponent<ILogoLoadingProps> = (props) => {
  return (
    <div className={styles.logoLoading}>
      <img src={Logo} alt="logo" />
    </div>
  );
};

export default LogoLoading;
