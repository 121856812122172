import React, { FC, PropsWithChildren } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import styles from "./styles.module.scss";

type IEmbeddedProps = PropsWithChildren<{}>;

const Embedded: FC<IEmbeddedProps> = (props) => {
  const { id: slug } = useParams();

  return (
    <>
      <iframe
        src={`${process.env.REACT_APP_S_URL}/widgets/epd/?game=${slug}&tenant_id=${window.TENANT_ID}`}
        title="Game"
        className={styles.game}
        id="iframeGame"
        allowFullScreen
      ></iframe>
    </>
  );
};

export default Embedded;
