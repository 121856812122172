import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useQueryClient } from "@tanstack/react-query";
import { MeReply } from "@greyhole/myid-connect/myid_pb";
import { MyID } from "@greyhole/myid-connect/myid_connect";

import styles from "./styles.module.scss";
import { CURRENCY_DEFAULT, CURRENCY_KEY, parseStringToObject } from "utils";
import DialogCurrency from "./DialogCurrency";
import { useSignOut } from "hooks/useSignOut";
import { dragonTransport, useClient } from "transport";
import { mapKeyToCurrency } from "utils/currency";

type ICurrencyProps = PropsWithChildren<{
  currencyVisible: boolean;
  toggleCurrency: (v: boolean) => void;
}>;

const Currency: FC<ICurrencyProps> = ({ currencyVisible, toggleCurrency }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const myIdClient = useClient(MyID, dragonTransport("bison"));

  const [currencySelected, setCurrencySelected] = useState(CURRENCY_DEFAULT);
  const currencyTmp = useRef("");
  const { logoutAndRegister } = useSignOut();

  const queryClient = useQueryClient();
  const meReply = queryClient.getQueryData<MeReply>(
    myIdClient.me.getQueryKey()
  );

  useEffect(() => {
    const metadata = parseStringToObject(meReply?.user?.metadata);
    setCurrencySelected(metadata?.currency || CURRENCY_DEFAULT);
    localStorage.setItem(CURRENCY_KEY, metadata?.currency || CURRENCY_DEFAULT);
  }, [meReply?.user]);

  const changeCurrent = (currency: string) => {
    currencyTmp.current = currency;
    setVisible(false);
    openDialogConfirm();
  };

  const openDialogConfirm = () => {
    if (!meReply?.user) return;
    toggleCurrency(true);
  };

  const closeDialogConfirm = () => {
    toggleCurrency(false);
  };

  const open = () => {
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  const onConfirm = () => {
    localStorage.setItem(CURRENCY_KEY, currencyTmp.current);
    setCurrencySelected(currencyTmp.current);

    logoutAndRegister();
  };

  return (
    <>
      <div className={styles.langContainer}>
        <div className={styles.langTitle} onClick={open}>
          <span className="text-bold">
            {mapKeyToCurrency[currencySelected]?.shorthand || "VND"}
          </span>
          <strong>{mapKeyToCurrency[currencySelected]?.unit}</strong>
          <i
            className={`fa ${visible ? "fa-chevron-up" : "fa-chevron-down"}`}
          ></i>
        </div>
        <div className={`${styles.langChoice} ${visible && styles.active}`}>
          <ul>
            {Object.entries(mapKeyToCurrency).map(([currency, info]) => {
              return (
                <li
                  key={currency}
                  onClick={() => changeCurrent(currency)}
                  className={currency === currencySelected ? styles.active : ""}
                >
                  <strong>{info.unit}</strong>
                  <span>{info.title} - {info.shorthand}</span>
                </li>
              );
            })}
          </ul>
          <div className={styles.overlay} onClick={close}></div>
        </div>
      </div>

      {currencyVisible && (
        <DialogCurrency
          onClose={closeDialogConfirm}
          currency={currencyTmp.current}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

export default Currency;
