export enum FilterConfig {
  CATEGORIES = "categories",
}

export enum Category {
  ALL_GAME = "CATEGORY_ALL",
  SLOT_GAME = "CATEGORY_SLOTS",
  TABLE_GAME = "CATEGORY_TABLE",
  CARD_GAME = "CATEGORY_CARDS",
  FISHING_GAME = "CATEGORY_FISHING",
  LOTTERY_GAME = "CATEGORY_LOTTERY",
  ARCADE_GAME = "CATEGORY_ARCADE",
  SPORT = "CATEGORY_SPORTS",
}
