import * as React from "react"

import styles from "./styles.module.scss"
import Sidebar from "components/Sidebar"

interface IListGameLayoutProps {}

const ListGameLayout: React.FunctionComponent<
  React.PropsWithChildren<IListGameLayoutProps>
> = ({ children }) => {
  return (
    <div className={styles.layoutContainer}>
      <div className={styles.filter}>
        <Sidebar />
      </div>
      <div className={styles.gamesList}>{children}</div>
    </div>
  )
}

export default ListGameLayout
