import * as React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import styles from "./styles.module.scss"

interface IBreadcrumbsProps {
  checkPoint: string
}

const Breadcrumbs: React.FunctionComponent<IBreadcrumbsProps> = ({
  checkPoint
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.breadcrumbs}>
      <ul>
        <li>
          <Link to="/">{t("ALL_GAME")}</Link>
        </li>
        <li>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.59998 6.34315L8.46861 7.47452L12.9941 12L8.46861 16.5255L9.59998 17.6569L15.2568 12L9.59998 6.34315Z"
              fill="#666666"
            />
          </svg>
        </li>
        <li className={styles.active}>
          <a href="#">{checkPoint}</a>
        </li>
      </ul>
    </div>
  )
}

export default Breadcrumbs
