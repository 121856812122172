import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { MyID } from "@greyhole/myid-connect/myid_connect";

import Logo from "assets/images/logo.svg";
import ArrowRight from "assets/images/arrow-right.svg";
import Input from "components/Input";
import { CURRENCY_KEY, generateRandomString } from "utils";
import { dragonTransport, useClient } from "transport";
import styles from "./styles.module.scss";
import Select from "components/Select";

function Register() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const registerBtnRef = useRef<HTMLButtonElement>(null);
  const { register, handleSubmit, setValue } = useForm<any>({
    defaultValues: {
      username: "",
      password: "",
      currency: localStorage.getItem(CURRENCY_KEY) || "vnd",
    },
  });
  const myIdClient = useClient(MyID, dragonTransport("bison"));
  const { mutateAsync: signUp, isPending } = useMutation(
    myIdClient.signUpV2.createUseMutationOptions()
  );
  const autoRegister = query.get("mode");

  useEffect(() => {
    if (autoRegister !== "auto") return;
    setValue("username", `at_${generateRandomString(8, "non-uppercase")}`);
    setValue("password", generateRandomString(12));
    registerBtnRef.current?.click();
  }, [autoRegister]);

  const onSubmit = async (d: any) => {
    try {
      const { tokenInfo } = await signUp({
        credential: {
          case: "myId",
          value: {
            ...d,
            confirmPassword: d.password,
          },
        },
        deviceId: generateRandomString(15),
        currency: d.currency,
      });

      if (tokenInfo?.accessToken) {
        localStorage.setItem("token", tokenInfo.accessToken);
        localStorage.setItem("idToken", tokenInfo.idToken);
        window.location.href = "/";
      }
    } catch (e) {
      console.log(e);
    }
  };

  const redirectToSignIn = () => {
    navigate("/sign-in");
  };

  return (
    <div className={styles.loginContainer}>
      <Link to="/" className={styles.logo}>
        <img src={Logo} alt="Logo" />
      </Link>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.loginForm}>
          <h1>{t("REGISTER")}</h1>
          <Select
            label={t("Currency")}
            placeholder={t("ENTER_USERNAME_OR_EMAIL")}
            register={register("currency")}
          />
          <Input
            label={t("USERNAME_OR_EMAIL")}
            placeholder={t("ENTER_USERNAME_OR_EMAIL")}
            register={register("username")}
          />
          <Input
            label={t("PASSWORD")}
            placeholder={t("ENTER_PASSWORD")}
            register={register("password")}
            type="password"
          />

          <button
            className={styles.loginButton}
            disabled={isPending}
            ref={registerBtnRef}
          >
            <span>{isPending ? `${t("LOADING")}...` : t("REGISTER")}</span>
          </button>
        </div>
      </form>

      <div className={styles.signInRedirect}>
        <div className="text">{t("DO_HAVE_ACCOUNT")}</div>
        <button className={styles.signUp} onClick={redirectToSignIn}>
          <span>{t("SIGN_IN_NOW")} </span>{" "}
          <img
            src={ArrowRight}
            alt="Redirect to Sign In"
            className={styles.arrowRight}
          />
        </button>
      </div>
    </div>
  );
}

export default Register;
