import { useMemo } from "react";
import { toast } from "react-toastify";
import i18next from "i18next";
import { ServiceType } from "@bufbuild/protobuf";
import { createGrpcWebTransport } from "@connectrpc/connect-web";
import {
  Code,
  ConnectError,
  Interceptor,
  Transport,
} from "@connectrpc/connect";
import { createQueryService } from "@connectrpc/connect-query";

type ServiceApiName = "bison" | "gnome" | "shoebill" | "hydra" | "koel";

export const getToken = () => localStorage.getItem("token") || "";

export const dragonTransport = (name: ServiceApiName, token?: string) => {
  return createGrpcWebTransport({
    baseUrl: `${process.env.REACT_APP_DRAGON_URL}/api/${name}`,
    interceptors: [
      setToken(token || getToken()),
      setXTenant(),
      loggerWithErrorHandler(),
    ],
  });
};

export const getAccessTokenTransport = (name: ServiceApiName) => {
  return createGrpcWebTransport({
    baseUrl: `${process.env.REACT_APP_DRAGON_URL}/api/${name}`,
    interceptors: [setXTenant(), loggerWithErrorHandler()],
  });
};

export const getMooseTransport = () => {
  return createGrpcWebTransport({
    baseUrl: `${process.env.REACT_APP_DRAGON_URL}/api/moose`,
    interceptors: [setXTenant(), loggerWithErrorHandler()],
  });
};

/**
 * Get a promise client for the given service.
 */
export function useClient<T extends ServiceType>(
  service: T,
  transport?: Transport
) {
  // We memoize the client, so that we only create one instance per service.
  return useMemo(
    () => createQueryService({ service, transport }),
    [service, transport]
  );
}

const setToken: (token?: string) => Interceptor = (token?: string) => {
  return (next) => async (req) => {
    if (token) req.header.set("Authorization", "Bearer " + token);
    return await next(req);
  };
};

const setXTenant: () => Interceptor = () => {
  return (next) => async (req) => {
    if (!!window.TENANT_ID) req.header.set("x-tenant-id", window.TENANT_ID);
    return await next(req);
  };
};

const loggerWithErrorHandler: () => Interceptor = () => {
  const { t } = i18next;

  return (next) => async (req) => {
    console.log(
      `%c gRPCClientRequest -> [${req.service.typeName} > ${req.method.name}] -> REQUEST:`,
      "background-color: #deeb34; color: #000; font-size: 14px",
      req
    );
    try {
      const res = await next(req);

      console.log(
        `%c>>>>> gRPCClientResponse -> [${req.service.typeName} > ${req.method.name}] -> SUCCESS:`,
        "background-color: #23d947; color: #000; font-size: 14px",
        res
      );

      return res;
    } catch (err) {
      const connectErr = ConnectError.from(err);
      switch (connectErr?.code) {
        case Code.Unauthenticated:
          console.log(
            `%c>>>>> gRPCClientResponse -> [${req.service.typeName} > ${req.method.name}] -> ERROR -> UNAUTHENTICATED: `,
            "background-color: #c0392b; color: #000; font-size: 14px",
            err
          );

          const idToken = localStorage.getItem("idToken");
          if (!idToken) {
            break;
          } else {
            return Promise.reject(connectErr);
          }

        default:
          console.log(
            `%c>>>>> gRPCClientResponse  -> [${req.service.typeName}] > ${req.method.name}] -> ERROR: `,
            "background-color: #c0392b; color: #000; font-size: 14px",
            err
          );
          break;
      }

      let grpcStatus = +(connectErr.metadata.get("grpc-status") || 0);
      if (!grpcStatus) {
        const msgSplit = connectErr.rawMessage.split("grpc-status:");
        if (msgSplit[0] === "HTTP 401") grpcStatus = Code.Unauthenticated;
        else grpcStatus = +msgSplit?.[1]?.trim() || Code.Unknown;
      }

      let msg = "";
      if (+grpcStatus === Code.Unauthenticated) msg = "ERROR.PLEASE_LOGIN";
      if (+grpcStatus < 20 && +grpcStatus !== Code.Unauthenticated)
        msg = `ERROR.COMMON.${grpcStatus}`;
      if (+grpcStatus > 20) {
        msg = `ERROR.${req.method.name.toUpperCase()}.${grpcStatus}`;
      }

      toast.error(t(msg));
      return Promise.reject(connectErr);
    }
  };
};
