import React, { FC, PropsWithChildren, useLayoutEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Game } from "@marketplace/myplace-connect/myplace_pb";
import { Provider } from "@marketplace/mysbo-connect/mysbo_pb";

import styles from "./styles.module.scss";
import { useCreateSessionMyAdapter } from "hooks/useCreateSessionMyAdapter";
import { useCurrency } from "hooks/useCurrency";
import { SABA_GAME_ID } from "utils/constant";

type ISBOGameIframeProps = PropsWithChildren<{
  game?: Game;
}>;

const SPORT_GAME_ID = "sport01";

const SBOGameIframe: FC<ISBOGameIframeProps> = ({ game }) => {
  const { i18n } = useTranslation();
  const {
    mutateAsync: createSession,
    data,
    isError,
  } = useCreateSessionMyAdapter();
  const { getCurrency } = useCurrency();

  const getProvider = () => {
    if (game?.gameId === SABA_GAME_ID) return Provider.SABA;
    return Provider.SBO;
  };

  useLayoutEffect(() => {
    createSession({ gameId: SPORT_GAME_ID });
  }, []);

  const paths = useMemo(() => {
    if (!data?.token) return;
    return [
      `gameId=${SPORT_GAME_ID}`,
      `tenantId=${window.TENANT_ID}`,
      `tenant_player_token=${data?.token}`,
      `language=${i18n.language}`,
      `currency=${getCurrency()}`,
      `mode=web`,
      `provider=${getProvider()}`,
    ];
  }, [data?.token]);

  if (isError) {
    return (
      <div className={styles.extGame}>
        <div>Game Not Found</div>
      </div>
    );
  }

  return (
    <div className={styles.extGame}>
      {!paths ? (
        <></>
      ) : (
        <iframe
          src={`${process.env.REACT_APP_S_URL}/widgets/sbo/?${paths.join("&")}`}
          title="SBO Games"
        ></iframe>
      )}
    </div>
  );
};

export default SBOGameIframe;
