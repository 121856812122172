import React, { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import { getToken } from "transport";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";

type ILoginToPlayGameProps = PropsWithChildren<{}>;

const LoginToPlayGame: FC<ILoginToPlayGameProps> = ({ children }) => {
  const { t } = useTranslation();

  if (!getToken()) {
    return (
      <div className={styles.loginToPlayGame}>
        <Link to={"/sign-in"}>{t("LOGIN")}</Link>{" "}
        {t("PLAY_THIS_GAME").toLowerCase()}
      </div>
    );
  }
  return <>{children}</>;
};

export default LoginToPlayGame;
