export const mapKeyToCurrency: Record<
  string,
  { title: string; icon: string; shorthand: string; unit: string }
> = {
  vno: {
    title: "Việt Nam Đồng",
    icon: "fa-solid fa-dong-sign",
    shorthand: "VNO",
    unit: "đ",
  },
  vnd: {
    title: "Việt Nam Đồng 1000",
    icon: "fa-solid fa-dong-sign",
    shorthand: "VND",
    unit: "đ",
  },
  usd: {
    title: "US Dollar",
    icon: "fa-solid fa-dollar-sign",
    shorthand: "USD",
    unit: "$",
  },
  cny: {
    title: "China",
    icon: "fa-solid fa-dollar-sign",
    shorthand: "CNY",
    unit: "¥",
  },
  tnd: {
    title: "New Taiwan dollar",
    icon: "fa-solid fa-dollar-sign",
    shorthand: "TND",
    unit: "NT$",
  },
  myr: {
    title: "Malaysian ringgit",
    icon: "fa-solid fa-dollar-sign",
    shorthand: "MYR",
    unit: "RM",
  },
  idr: {
    title: "Indonesian rupiah",
    icon: "fa-solid fa-dollar-sign",
    shorthand: "IDR",
    unit: "Rp",
  },
  thb: {
    title: "Thai baht",
    icon: "fa-solid fa-dollar-sign",
    shorthand: "THB",
    unit: "฿",
  },
};
