import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { MyPlace } from "@marketplace/myplace-connect/myplace_connect";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import GameLayout from "./GameLayout";
import SBOGameIframe from "./SBOGameIframe";
import NauGameIframe from "./NauGameIframe";
import { withMainLayout } from "layout/withMainLayout";
import NodeLoading from "components/NodeLoading";
import { getMooseTransport, useClient } from "transport";
import { LABEL_SBOGAME, LOTTERY02_GAME_ID } from "utils/constant";
import GameNotFound from "components/GameNotFound";
import LoginToPlayGame from "components/LoginToPlayGame";
import Lottery02Iframe from "./Lottery02Iframe";
import { Game } from "@marketplace/myplace-connect/myplace_pb";

interface IPlayGameProps {}

const PlayGame: React.FunctionComponent<IPlayGameProps> = (props) => {
  const { i18n } = useTranslation();
  const { id: slug } = useParams();

  const myPlaceClient = useClient(MyPlace);

  const { data, isLoading: loading } = useQuery(
    myPlaceClient.getGame.createUseQueryOptions(
      {
        lang: i18n.language,
        tenantId: window.TENANT_ID,
        slug,
      },
      { transport: getMooseTransport() }
    )
  );

  const sboGameLabel = useMemo(() => {
    const label = data?.game?.labels?.find((l) => {
      return [LABEL_SBOGAME].includes(l);
    });
    return !!label;
  }, [data?.game?.gameId]);

  const lottery02 = useMemo(() => {
    return data?.game?.gameId === LOTTERY02_GAME_ID;
  }, [data?.game?.gameId]);

  const gameRender = useCallback(
    (loading: boolean) => {
      const label = data?.game?.labels?.find((l) => {
        return [LABEL_SBOGAME].includes(l);
      });
      if (!!label) {
        return <SBOGameIframe game={data?.game} />;
      }

      if (data?.game?.gameId === LOTTERY02_GAME_ID) {
        return <Lottery02Iframe game={data?.game} />;
      }
      return <NauGameIframe game={data?.game} loading={loading} />;
    },
    [data?.game?.gameId]
  );

  return (
    <NodeLoading loading={loading}>
      <LoginToPlayGame>
        <GameNotFound notFound={!data?.game}>
          <GameLayout containerFull={sboGameLabel || lottery02}>
            {gameRender(loading)}
            <br />
            <br />
          </GameLayout>
        </GameNotFound>
      </LoginToPlayGame>
    </NodeLoading>
  );
};

export default withMainLayout(PlayGame, { headerShow: true });
