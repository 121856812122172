import * as React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import styles from "./styles.module.scss"
import Logged from "./logged"

interface IProfileProps {}

const Profile: React.FunctionComponent<IProfileProps> = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const redirectToSignIn = () => {
    navigate("/sign-in")
  }

  const redirectToSignUpZeroClick = () => {
    navigate("/sign-up?mode=auto")
  }

  const hasToken = React.useCallback(() => !!localStorage.getItem("token"), [])

  return (
    <div>
      {!hasToken() ? (
        <>
          <div className={styles.signIn}>
            <div className={styles.buttonGroup}>
              <button onClick={redirectToSignIn}>
                <span>{t("LOGIN")}</span>
              </button>
              <button onClick={redirectToSignUpZeroClick}>
                <i className="fa-solid fa-bullseye-pointer"></i>
              </button>
            </div>
          </div>
          <div className={`${styles.buttonGroup} ${styles.signInMobile}`}>
            <button onClick={redirectToSignIn}>
              <i
                className="fa-solid fa-arrow-right-to-bracket"
                onClick={redirectToSignIn}
              ></i>
            </button>
            <button onClick={redirectToSignUpZeroClick}>
              <i className="fa-solid fa-bullseye-pointer"></i>
            </button>
          </div>
        </>
      ) : (
        <Logged />
      )}
    </div>
  )
}

export default Profile
