import React, { FC, PropsWithChildren, useLayoutEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Game } from "@marketplace/myplace-connect/myplace_pb";

import styles from "./styles.module.scss";
import { useCreateSessionMyAdapter } from "hooks/useCreateSessionMyAdapter";
import { useCurrency } from "hooks/useCurrency";
import { LOTTERY02_GAME_ID } from "utils/constant";

type ILottery02IframeProps = PropsWithChildren<{
  game?: Game;
}>;

const Lottery02Iframe: FC<ILottery02IframeProps> = ({ game }) => {
  const { i18n } = useTranslation();
  const {
    mutateAsync: createSession,
    data,
    isError,
  } = useCreateSessionMyAdapter();
  const { getCurrency } = useCurrency();

  useLayoutEffect(() => {
    createSession({ gameId: LOTTERY02_GAME_ID });
  }, []);

  const paths = useMemo(() => {
    if (!data?.token) return;
    return [
      `gameId=${LOTTERY02_GAME_ID}`,
      `tenantId=${window.TENANT_ID}`,
      `tenant_player_token=${data?.token}`,
      `language=${i18n.language}`,
      `currency=${getCurrency()}`,
      `mode=web`,
    ];
  }, [data?.token]);

  if (isError) {
    return (
      <div className={styles.extGame}>
        <div>Game Not Found</div>
      </div>
    );
  }

  return (
    <div className={styles.extGame}>
      {paths && (
        <iframe
          src={`${process.env.REACT_APP_S_URL}/widgets/lottery02/?${paths.join(
            "&"
          )}`}
          title="Lottery02"
        ></iframe>
      )}
    </div>
  );
};

export default Lottery02Iframe;
