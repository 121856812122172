import React, { FC, PropsWithChildren } from "react";

import styles from "./styles.module.scss";

type IControlBarProps = PropsWithChildren<{
  reload: () => void;
  rotate: () => void;
  expandScreen: () => void;
  isMobile?: boolean;
}>;

const ControlBar: FC<IControlBarProps> = ({
  expandScreen,
  reload,
  rotate,
  isMobile,
}) => {
  const mobileElt = isMobile ? (
    <i className="fa-solid fa-desktop" onClick={rotate}></i>
  ) : (
    <i className="fa-solid fa-mobile" onClick={rotate}></i>
  );
  
  return (
    <div className={styles.bottomGameContainer}>
      <div className={styles.actionBox}>
        <i className="fa-solid fa-rotate-right" onClick={reload}></i>
        {mobileElt}
        <i className="fa-solid fa-maximize" onClick={expandScreen}></i>
      </div>
    </div>
  );
};

export default ControlBar;
