import React, { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Game } from "@marketplace/myplace-connect/myplace_pb";

import styles from "./styles.module.scss";
import { getResource } from "utils";

type IBackgroundCoverProps = PropsWithChildren<{
  game?: Game;
}>;

const BackgroundCover: FC<IBackgroundCoverProps> = ({ game }) => {
  const { i18n, t } = useTranslation();

  return (
    <div className={styles.bgCover}>
      <img src={getResource(game?.image320x468)} />
    </div>
  );
};

export default BackgroundCover;
