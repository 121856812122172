import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { MyID } from "@greyhole/myid-connect/myid_connect";

import Logo from "assets/images/logo.svg";
import ArrowRight from "assets/images/arrow-right.svg";
import Input from "components/Input";
import { dragonTransport, useClient } from "transport";
import styles from "./styles.module.scss";

function App() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const myIdClient = useClient(MyID, dragonTransport("bison"));
  const { mutateAsync: signIn, isPending } = useMutation(
    myIdClient.signInV2.createUseMutationOptions()
  );

  const onSubmit = async (d: any) => {
    console.log("d", d);

    try {
      const res = await signIn({
        credential: {
          case: "myId",
          value: {
            ...d,
          },
        },
      });

      if (res.result.case === "tokenInfo") {
        localStorage.setItem("token", res.result.value.accessToken);
        localStorage.setItem("idToken", res.result.value.idToken);
        window.location.href = "/";
      }
    } catch (e) {
      console.log(e);
    }
  };

  const redirectToSignUp = () => {
    navigate("/sign-up");
  };

  return (
    <div className={styles.loginContainer}>
      <Link to="/" className={styles.logo}>
        <img src={Logo} alt="Logo" />
      </Link>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.loginForm}>
          <h1>{t("LOGIN")}</h1>
          <Input
            label={t("USERNAME_OR_EMAIL")}
            placeholder={t("ENTER_USERNAME_OR_EMAIL")}
            register={register("username")}
          />
          <Input
            label={t("PASSWORD")}
            placeholder={t("ENTER_PASSWORD")}
            register={register("password")}
            type="password"
          />

          <Link to="/sign-up" className={styles.forgotPw}>
            {t("FORGOT_PASSWORD")}?
          </Link>

          <button className={styles.loginButton} disabled={isPending}>
            <span>{isPending ? `${t("LOADING")}...` : t("LOGIN")}</span>
          </button>
        </div>
      </form>

      <div className={styles.signInRedirect}>
        <div className="text">{t("DONT_HAVE_ACCOUNT")}</div>
        <button className={styles.signUp} onClick={redirectToSignUp}>
          <span>{t("SIGN_UP_NOW")} </span>{" "}
          <img
            src={ArrowRight}
            alt="Redirect to Sign Up"
            className={styles.arrowRight}
          />
        </button>
      </div>
    </div>
  );
}

export default App;
