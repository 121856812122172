import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import AllGameIcon from "components/icons/AllGame";
import ArcadeGame from "components/icons/ArcadeGame";
import SlotIcon from "components/icons/SlotIcon";
import TableIcon from "components/icons/TableIcon";
import CardGameIcon from "components/icons/CardGameIcon";
import FishingIcon from "components/icons/FishingIcon";
import InstantGameIcon from "components/icons/InstantGameIcon";
import { Category, FilterConfig } from "./const";
import styles from "./styles.module.scss";

interface ISidebarProps {}

type Menu = {
  title: string;
  value: Category;
  iconComponent: React.ReactNode;
};

const MENUS: Menu[] = [
  {
    title: "ALL_GAME",
    value: Category.ALL_GAME,
    iconComponent: <AllGameIcon />,
  },
  {
    title: "SLOT_GAME",
    value: Category.SLOT_GAME,
    iconComponent: <SlotIcon />,
  },
  {
    title: "TABLE_GAME",
    value: Category.TABLE_GAME,
    iconComponent: <TableIcon />,
  },
  {
    title: "CARD_GAME",
    value: Category.CARD_GAME,
    iconComponent: <CardGameIcon />,
  },
  {
    title: "FISHING_GAME",
    value: Category.FISHING_GAME,
    iconComponent: <FishingIcon />,
  },
  {
    title: "LOTTERY_GAME",
    value: Category.LOTTERY_GAME,
    iconComponent: <InstantGameIcon />,
  },
  {
    title: "ARCADE_GAME",
    value: Category.ARCADE_GAME,
    iconComponent: <ArcadeGame />,
  },
  {
    title: "SPORT",
    value: Category.SPORT,
    iconComponent: <i className="fa-light fa-futbol size-20"></i>,
  },
];

const Sidebar: React.FunctionComponent<ISidebarProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [query] = useSearchParams();

  const catIdQueryParam = query.get(FilterConfig.CATEGORIES);

  const onClick = (value: string) => {
    return () => {
      const curQueryParams = location.search;
      const queryParams = new URLSearchParams(curQueryParams);
      if (!value) {
        queryParams.delete(FilterConfig.CATEGORIES);
      } else {
        if (queryParams.has(FilterConfig.CATEGORIES)) {
          queryParams.set(FilterConfig.CATEGORIES, value.toString());
        } else {
          queryParams.append(FilterConfig.CATEGORIES, value.toString());
        }
      }

      navigate({
        ...location,
        search: queryParams.toString(),
      });
      window.scrollTo(0, 0);
    };
  };

  const cls = React.useCallback(
    (m: Menu) => {
      if (!catIdQueryParam && m.value === Category.ALL_GAME)
        return styles.active;

      if (catIdQueryParam === m.value.toString()) {
        return styles.active;
      }

      return "";
    },
    [catIdQueryParam]
  );

  return (
    <div className={styles.sidebar}>
      <ul>
        {MENUS.map((m) => {
          return (
            <li key={m.value} className={cls(m)} onClick={onClick(m.value)}>
              {m.iconComponent}
              <span>{t(m.title)}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Sidebar;
