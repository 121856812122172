import React, { FC, PropsWithChildren } from "react";

type IGameNotFoundProps = PropsWithChildren<{
  notFound?: boolean;
}>;

const GameNotFound: FC<IGameNotFoundProps> = ({ notFound, children }) => {
  if (!!notFound) return <div>Game Not Found</div>;
  return <>{children}</>;
};

export default GameNotFound;
