import React, {
  FC,
  KeyboardEvent,
  KeyboardEventHandler,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash-es";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { getKeyByValue } from "utils";
import { Category } from "components/Sidebar/const";
import styles from "./styles.module.scss";
import { FilterConfig } from "./const";

type ITitleProps = PropsWithChildren<{ categoryId: string; search: string }>;

const Title: FC<ITitleProps> = ({ categoryId, search }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(search);
  }, [search]);

  const [onChangeByDebounceState] = React.useState(() =>
    debounce((v: string) => {
      const curQueryParams = location.search;
      const queryParams = new URLSearchParams(curQueryParams);
      if (!v) {
        queryParams.delete(FilterConfig.SEARCH_GAME);
      } else {
        if (queryParams.has(FilterConfig.SEARCH_GAME)) {
          queryParams.set(FilterConfig.SEARCH_GAME, v.toString());
        } else {
          queryParams.append(FilterConfig.SEARCH_GAME, v.toString());
        }
      }
      navigate({
        ...location,
        search: queryParams.toString(),
      });
      window.scrollTo(0, 0);
    }, 1000)
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
    onChangeByDebounceState(e.currentTarget.value);
  };

  return (
    <div className={styles.gameTitle}>
      <h1>{t(getKeyByValue(categoryId, Category) || "")}</h1>
      <div className={styles.searchBox}>
        <input
          type="text"
          placeholder="Search game"
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default Title;
