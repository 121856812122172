import React, { InputHTMLAttributes } from "react";

import styles from "./styles.module.scss";

type IContainerProps = InputHTMLAttributes<HTMLDivElement> & {};

const Container: React.FunctionComponent<
  React.PropsWithChildren<IContainerProps>
> = ({ children, className, ...rest }) => {
  return (
    <div className={`${styles.container} ${className}`} {...rest}>
      {children}
    </div>
  );
};

export default Container;
