import React, { SVGProps } from 'react';

const Facebook = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      fill="none"
      viewBox="0 0 21 20"
      data-color="emphasize"
      {...props}>
      <g clipPath="url(#clip0_703_80886)">
        <path
          fill="currentColor"
          d="M20.5 10c0-5.523-4.477-10-10-10S.5 4.477.5 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.093 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.563V10h2.773l-.443 2.89h-2.33v6.988c4.78-.75 8.437-4.887 8.437-9.878z"></path>
      </g>
      <defs>
        <clipPath id="clip0_703_80886">
          <path fill="#fff" d="M0 0H20V20H0z" transform="translate(.5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Facebook;
