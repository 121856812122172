import * as React from "react";
import { UseFormRegisterReturn } from "react-hook-form";

import styles from "./styles.module.scss";

type IInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  placeholder: string;
  register?: UseFormRegisterReturn;
};

const Input: React.FunctionComponent<IInputProps> = ({
  label,
  register,
  ...rest
}) => {
  return (
    <div className={styles.formGroup}>
      <div className={styles.label}>{label}</div>
      <div className={styles.input}>
        <input {...rest} {...register} />
      </div>
    </div>
  );
};

export default Input;
