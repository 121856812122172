import { MyID } from "@greyhole/myid-connect/myid_connect";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { dragonTransport, useClient } from "transport";

export const useSignOut = () => {
  const navigate = useNavigate();
  const myIdClient = useClient(MyID, dragonTransport("bison"));
  const { mutateAsync: signOut } = useMutation(
    myIdClient.signOut.createUseMutationOptions({
      transport: dragonTransport("bison"),
    })
  );

  const onLogOut = async (register?: boolean) => {
    try {
      await signOut({});
      localStorage.removeItem("token");
      if (!register) {
        return location.reload();
      }

      navigate("/sign-up?mode=auto");
    } catch (error) {
      console.log("err", error);
    }
  };

  const logOut = () => {
    setTimeout(() => onLogOut(), 112);
  };

  const logoutAndRegister = () => {
    setTimeout(() => onLogOut(true), 112);
  };

  return { logOut, logoutAndRegister };
};
