import Container from "components/Container";
import React, { FC, PropsWithChildren, useMemo } from "react";

import styles from "./styles.module.scss";

type IGameLayoutProps = PropsWithChildren<{
  containerFull?: boolean;
}>;

const GameLayout: FC<IGameLayoutProps> = ({ containerFull, children }) => {
  if (containerFull) {
    return <div className="header-mb">{children}</div>;
  }

  return (
    <Container
      className={`mbi-88 ${styles.playGameMobile} ${styles.playGameContainer}`}
    >
      {children}
    </Container>
  );
};

export default GameLayout;
