import * as React from "react";

import styles from "./styles.module.scss";
import LinkedIn from "assets/images/socials/linkedin.svg";
import Twitter from "assets/images/socials/twitter.svg";
import Youtube from "assets/images/socials/youtube.svg";

interface ISocialProps {}

const Social: React.FunctionComponent<ISocialProps> = (props) => {
  return (
    <div className={styles.social}>
      <a href="#">
        <img src={Twitter} alt="Twitter" />
      </a>
      <a href="#">
        <img src={Youtube} alt="Youtube" />
      </a>
      <a href="#">
        <img src={LinkedIn} alt="Linkedin" />
      </a>
    </div>
  );
};

export default Social;
