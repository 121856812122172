import * as React from "react";

interface IInstantGameIconProps {}

const InstantGameIcon: React.FunctionComponent<IInstantGameIconProps> = (
  props
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.11735 12.1025C4.51609 11.7666 5.02481 11.5898 5.54592 11.6059C6.06719 11.622 6.56414 11.8301 6.9414 12.1902L6.94847 12.1969C7.736 12.9745 7.72846 14.2276 7.04774 15.0336C6.75171 15.3857 6.33936 15.649 5.93305 15.8475C5.51984 16.0493 5.07185 16.2036 4.66685 16.3195C4.26002 16.4359 3.88439 16.5169 3.61095 16.5688C3.47384 16.5948 3.36142 16.6137 3.28242 16.6263C3.24288 16.6325 3.21165 16.6372 3.18979 16.6404L3.16415 16.644L3.15685 16.645L3.15462 16.6453L3.15358 16.6455C3.15345 16.6455 3.15335 16.6455 3.0769 16.0736C2.50506 15.9973 2.50508 15.9971 2.50509 15.997L2.50554 15.9937L2.50655 15.9864L2.51021 15.9608C2.51338 15.9389 2.51806 15.9077 2.52432 15.8682C2.53685 15.7892 2.55577 15.6767 2.5818 15.5396C2.63372 15.2662 2.7147 14.8906 2.83107 14.4837C2.94692 14.0787 3.10122 13.6307 3.30306 13.2176C3.5016 12.8111 3.76506 12.3986 4.11735 12.1025ZM3.0769 16.0736L2.50509 15.997C2.48128 16.1752 2.54184 16.3545 2.66895 16.4816C2.79607 16.6087 2.97539 16.6693 3.15358 16.6455L3.0769 16.0736ZM3.79976 15.3508C3.96955 15.3121 4.15611 15.2655 4.34953 15.2102C4.71578 15.1054 5.09462 14.9729 5.42663 14.8107C5.76524 14.6453 6.01619 14.4677 6.16485 14.2907L6.16599 14.2894C6.48608 13.9106 6.46549 13.3455 6.14114 13.0214C5.97005 12.8598 5.74561 12.7665 5.51025 12.7592C5.27325 12.7519 5.04188 12.8323 4.86056 12.9851L4.85985 12.9857C4.68287 13.1344 4.50523 13.3853 4.33983 13.7239C4.17767 14.0559 4.04518 14.4348 3.94042 14.801C3.8851 14.9945 3.83845 15.181 3.79976 15.3508Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2466 2.90164C14.9989 2.98078 13.7815 3.33388 12.6819 3.93865C11.4089 4.63878 10.3362 5.65294 9.56586 6.88468L9.56263 6.88985C9.08747 7.63202 8.67886 8.41456 8.3417 9.22818L9.92309 10.8096C10.7465 10.4731 11.5391 10.0649 12.2916 9.58971C15.4007 7.50188 16.1299 4.74934 16.2466 2.90164ZM12.1258 2.92764C13.5712 2.13264 15.196 1.72067 16.8456 1.7309C17.1629 1.73287 17.419 1.99058 17.419 2.30781C17.419 4.34115 16.8286 7.93679 12.9277 10.5523L12.9148 10.5608C11.9905 11.1453 11.0099 11.6356 9.9877 12.0243C9.7754 12.105 9.5354 12.0536 9.37471 11.893L7.25689 9.77518C7.09556 9.61387 7.04446 9.37264 7.12651 9.15971C7.51577 8.14987 8.00578 7.18181 8.58924 6.27023C9.46386 4.87278 10.6812 3.72214 12.1258 2.92764Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.63575 6.79626C6.30303 6.82719 6.04506 6.90584 5.86877 7.02323C5.52438 7.25387 5.22257 7.79781 5.00093 8.40889C4.95278 8.54158 4.91083 8.6705 4.87482 8.79027H7.6662C7.98483 8.79027 8.24314 9.04858 8.24314 9.36719C8.24314 9.68581 7.98483 9.94412 7.6662 9.94412H4.1365C3.96545 9.94412 3.80323 9.86827 3.69362 9.73696C3.584 9.60566 3.5383 9.4325 3.56885 9.26419L4.1365 9.36719C3.56885 9.26419 3.56883 9.26435 3.56885 9.26419L3.56909 9.26289L3.56947 9.26081L3.57068 9.25435L3.57489 9.23243C3.5785 9.21389 3.58374 9.18781 3.59067 9.15496C3.60452 9.08927 3.62519 8.99635 3.6532 8.88343C3.70902 8.65835 3.79508 8.34943 3.91623 8.01543C4.14455 7.38598 4.54853 6.51821 5.2276 6.06394L5.22836 6.06343C5.62387 5.79975 6.09289 5.68789 6.52897 5.64736C6.97002 5.60637 7.42093 5.63446 7.81583 5.68536C8.21299 5.73655 8.5693 5.81291 8.82553 5.87609C8.95414 5.90781 9.05884 5.93653 9.13245 5.95763C9.16922 5.96819 9.1983 5.97687 9.21883 5.9831L9.24299 5.99055L9.25007 5.99276L9.2523 5.99347L9.25314 5.99373C9.25322 5.99376 9.25368 5.9939 9.08845 6.51092L9.25314 5.99373C9.5566 6.0907 9.7246 6.41555 9.6276 6.71906C9.53068 7.02242 9.2063 7.18983 8.90291 7.09315C8.90276 7.0931 8.90307 7.09319 8.90291 7.09315L9.07807 6.54346C8.90245 7.09301 8.90283 7.09312 8.90291 7.09315L8.89937 7.09204L8.88322 7.08706C8.86814 7.08249 8.84491 7.07554 8.81422 7.06674C8.75283 7.04912 8.66222 7.02423 8.5493 6.99639C8.32237 6.94044 8.0106 6.87386 7.66834 6.82974C7.32378 6.78533 6.96373 6.76578 6.63575 6.79626Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4321 9.52375C12.7356 9.42675 13.0603 9.59421 13.1573 9.89767L12.6076 10.0733C13.1573 9.89767 13.1572 9.8976 13.1573 9.89767L13.1576 9.89906L13.1583 9.90129L13.1606 9.90837L13.168 9.93252C13.1743 9.95306 13.183 9.98213 13.1935 10.019C13.2146 10.0925 13.2433 10.1972 13.275 10.3259C13.3382 10.5821 13.4146 10.9384 13.4658 11.3355C13.5166 11.7304 13.5448 12.1814 13.5038 12.6224C13.4633 13.0585 13.3514 13.5275 13.0877 13.923L13.0872 13.9237C12.633 14.6028 11.7652 15.0068 11.1357 15.2351C10.8017 15.3563 10.4927 15.4424 10.2677 15.4981C10.1548 15.5262 10.0619 15.5468 9.99619 15.5607C9.96334 15.5676 9.93719 15.5729 9.91872 15.5765L9.8968 15.5807L9.89034 15.5819L9.88826 15.5823L9.88719 15.5824C9.88711 15.5825 9.88695 15.5825 9.78395 15.0149L9.88719 15.5824C9.71888 15.6131 9.54549 15.5674 9.41419 15.4577C9.28288 15.3481 9.20703 15.1859 9.20703 15.0149V11.4851C9.20703 11.1665 9.46526 10.9082 9.78395 10.9082C10.1026 10.9082 10.3609 11.1665 10.3609 11.4851V14.2765C10.4806 14.2405 10.6096 14.1986 10.7423 14.1504C11.3533 13.9288 11.8973 13.627 12.1279 13.2826C12.2453 13.1063 12.324 12.8484 12.3549 12.5156C12.3853 12.1877 12.3658 11.8276 12.3214 11.4831C12.2773 11.1407 12.2107 10.829 12.1547 10.6021C12.1269 10.4891 12.102 10.3985 12.0844 10.3371C12.0756 10.3064 12.0686 10.2832 12.0641 10.2682L12.0591 10.252L12.0581 10.2489C11.9613 9.94544 12.1286 9.62067 12.4321 9.52375Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default InstantGameIcon;
