import { useLayoutEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LogoLoading from "components/LogoLoading";
import Register from "pages/Authentication/Register";
import Home from "pages/Home";
import Login from "./pages/Authentication/Login";
import PlayGame from "pages/PlayGame";
import { useAuth } from "hooks/useAuth";
import { useSignUpAndSignIn } from "hooks/useAutoSignUpAndSignIn";
import Embedded from "pages/Embedded";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/sign-in",
    element: <Login />,
  },
  {
    path: "/sign-up",
    element: <Register />,
  },
  {
    path: "/games/:id/play",
    element: <PlayGame />,
  },
  {
    path: "/games/:id/embedded",
    element: <Embedded />,
  },
]);

const App = () => {
  const { getMe } = useAuth();
  const { loading: autoSignUpAndSignInLoading, autoSignUpAndSignIn } =
    useSignUpAndSignIn();

  useLayoutEffect(() => {
    getMe();
  }, [getMe]);

  useLayoutEffect(() => {
    autoSignUpAndSignIn();
  }, []);

  if (autoSignUpAndSignInLoading) return <LogoLoading />;

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default App;
