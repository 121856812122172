import React, { SVGProps } from 'react';

const Instagram = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      fill="none"
      viewBox="0 0 21 20"
      data-color="emphasize"
      {...props}>
      <g fill="currentColor" clipPath="url(#clip0_703_80887)">
        <path d="M10.5 1.8c2.672 0 2.988.012 4.04.06.976.042 1.503.206 1.855.343.464.18.8.399 1.148.746.352.352.566.684.746 1.149.137.351.3.882.344 1.855.047 1.055.058 1.371.058 4.04 0 2.671-.011 2.987-.058 4.038-.043.977-.207 1.504-.344 1.856-.18.465-.398.8-.746 1.148a3.077 3.077 0 01-1.148.746c-.352.137-.883.301-1.856.344-1.055.047-1.371.059-4.039.059-2.672 0-2.988-.012-4.04-.059-.976-.043-1.503-.207-1.855-.344-.464-.18-.8-.398-1.148-.746a3.076 3.076 0 01-.746-1.148c-.137-.352-.3-.883-.344-1.856-.047-1.054-.058-1.37-.058-4.039 0-2.672.011-2.988.058-4.039.043-.976.207-1.504.344-1.855.18-.465.398-.801.746-1.149a3.076 3.076 0 011.148-.746c.352-.137.883-.3 1.856-.344 1.05-.046 1.367-.058 4.039-.058zm0-1.8C7.785 0 7.445.012 6.379.059 5.316.105 4.586.277 3.953.523A4.88 4.88 0 002.18 1.68a4.9 4.9 0 00-1.157 1.77C.777 4.085.605 4.811.56 5.874.512 6.945.5 7.285.5 10s.012 3.055.059 4.121c.046 1.063.218 1.793.464 2.426.258.66.598 1.219 1.157 1.773a4.888 4.888 0 001.77 1.153c.636.246 1.362.418 2.425.465 1.066.046 1.406.058 4.121.058 2.715 0 3.055-.012 4.121-.058 1.063-.047 1.793-.22 2.426-.465a4.888 4.888 0 001.77-1.153 4.888 4.888 0 001.152-1.77c.246-.636.418-1.363.465-2.425.047-1.066.058-1.406.058-4.121 0-2.715-.011-3.055-.058-4.121-.047-1.063-.22-1.793-.465-2.426A4.683 4.683 0 0018.82 1.68 4.889 4.889 0 0017.05.527C16.415.281 15.688.11 14.626.062 13.555.012 13.215 0 10.5 0z"></path>
        <path d="M10.5 4.863A5.138 5.138 0 005.363 10a5.138 5.138 0 0010.273 0c0-2.836-2.3-5.137-5.136-5.137zm0 8.469a3.333 3.333 0 11.001-6.665 3.333 3.333 0 01-.001 6.665zM17.04 4.66a1.2 1.2 0 11-2.4 0 1.2 1.2 0 012.4 0z"></path>
      </g>
      <defs>
        <clipPath id="clip0_703_80887">
          <path fill="#fff" d="M0 0H20V20H0z" transform="translate(.5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Instagram;
