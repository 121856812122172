import { useMutation } from "@tanstack/react-query";
import { MyAdapter } from "@marketplace/myadapter-connect/myadapter_connect";

import { dragonTransport, useClient } from "transport";

export const useCreateSessionMyAdapter = () => {
  return useMutation(
    useClient(MyAdapter).createSession.createUseMutationOptions({
      transport: dragonTransport("shoebill"),
    })
  );
};
