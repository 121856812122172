export const CURRENCY_KEY = "__CURRENCY_KEY__";
export const CURRENCY_DEFAULT = "usd";
export const CURRENCY_PARAM = "currency";

export function formatString(str: string) {
  let formattedString = str.toLowerCase();
  formattedString = formattedString.replace(/[^a-z0-9]/g, "");
  return formattedString;
}

export function generateRandomString(
  length: number,
  mode?: "full" | "non-uppercase"
) {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  if (mode === "non-uppercase") {
    characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  }

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}

const mapLangToLanguageEnum: Record<string, string> = {
  en: "LANGUAGE_EN",
  vi: "LANGUAGE_VI",
};

console.log("mapLangToLanguageEnum", mapLangToLanguageEnum);

export const getKeyByValue = (value: string, myEnum: any) => {
  for (let key in myEnum) {
    if (myEnum[key] === value) {
      return key;
    }
  }
  return null;
};

export const getResource = (path?: string) => {
  if (!path) return;
  return `${process.env.REACT_APP_S_URL}/${path}`;
};

export const parseStringToObject = (data?: string) => {
  if (!data) return;
  try {
    return JSON.parse(data);
  } catch (error) {
    return;
  }
};
