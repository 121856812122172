import * as React from "react";

interface ITableIconProps {}

const TableIcon: React.FunctionComponent<ITableIconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      {...props}
    >
      <path
        d="M4.50064 5.00505C3.71067 5.79099 3.08439 6.72577 2.65802 7.75531C2.23165 8.78485 2.01365 9.88872 2.01664 11.0031C2.01344 12.1177 2.23133 13.2219 2.65771 14.2518C3.08409 15.2817 3.71048 16.2168 4.50064 17.0031C5.28665 17.7933 6.22156 18.4188 7.25127 18.8453C8.28099 19.2719 9.38507 19.49 10.4996 19.4871C11.6142 19.49 12.7183 19.2719 13.748 18.8453C14.7777 18.4188 15.7126 17.7923 16.4986 17.0021C17.2887 16.216 17.915 15.281 18.3414 14.2513C18.7677 13.2216 18.9857 12.1175 18.9826 11.0031C18.9857 9.8888 18.7678 8.78499 18.3416 7.75546C17.9154 6.72593 17.2894 5.79111 16.4996 5.00505C15.7135 4.21471 14.7784 3.58814 13.7485 3.16159C12.7186 2.73504 11.6144 2.51698 10.4996 2.52005C9.38507 2.51706 8.28097 2.73515 7.25124 3.1617C6.22152 3.58825 5.28661 4.21479 4.50064 5.00505ZM15.5956 16.0991C14.928 16.7704 14.1338 17.3027 13.2591 17.6651C12.3844 18.0274 11.4465 18.2127 10.4996 18.2101C9.55288 18.2128 8.61498 18.0278 7.74025 17.6656C6.86551 17.3034 6.07131 16.7713 5.40364 16.1001C4.73232 15.4322 4.20016 14.6379 3.83798 13.7629C3.47579 12.888 3.29077 11.95 3.29364 11.0031C3.29364 9.07805 4.04264 7.26905 5.40364 5.90705C6.07134 5.23586 6.86555 4.70377 7.74028 4.34159C8.61501 3.9794 9.55289 3.79432 10.4996 3.79705C12.4246 3.79705 14.2346 4.54705 15.5956 5.90705C16.2669 6.57472 16.799 7.36892 17.1612 8.24366C17.5234 9.1184 17.7084 10.0563 17.7056 11.0031C17.7056 12.9291 16.9566 14.7381 15.5956 16.0991Z"
        fill="currentColor"
      />
      <path
        d="M13.5363 8.99801C13.4143 8.99826 13.2942 9.02785 13.186 9.08429C13.0779 9.14074 12.985 9.22237 12.9151 9.32231C12.8451 9.42224 12.8003 9.53753 12.7843 9.65845C12.7683 9.77936 12.7817 9.90235 12.8233 10.017L11.9723 10.537L10.9943 8.77401C11.1162 8.67548 11.2045 8.54158 11.2472 8.39075C11.2898 8.23992 11.2846 8.07958 11.2324 7.93181C11.1801 7.78403 11.0833 7.65609 10.9554 7.5656C10.8274 7.4751 10.6745 7.42651 10.5178 7.42651C10.361 7.42651 10.2081 7.4751 10.0801 7.5656C9.95215 7.65609 9.85537 7.78403 9.80312 7.93181C9.75086 8.07958 9.7457 8.23992 9.78835 8.39075C9.83099 8.54158 9.91935 8.67548 10.0413 8.77401L9.06325 10.538L8.18325 10C8.2356 9.84483 8.23643 9.67689 8.1856 9.5212C8.13477 9.36551 8.03501 9.23041 7.90117 9.13601C7.76734 9.04162 7.60659 8.99298 7.44287 8.99734C7.27915 9.00171 7.12123 9.05884 6.99261 9.16024C6.864 9.26163 6.77157 9.40186 6.72911 9.56003C6.68665 9.71821 6.69642 9.88587 6.75697 10.038C6.81751 10.1902 6.9256 10.3188 7.06512 10.4045C7.20464 10.4903 7.36813 10.5287 7.53125 10.514L8.45425 14.007H12.5803L13.5023 10.516H13.5363C13.7308 10.506 13.9141 10.4217 14.0483 10.2805C14.1825 10.1392 14.2573 9.95183 14.2573 9.75701C14.2573 9.5622 14.1825 9.37482 14.0483 9.23357C13.9141 9.09233 13.7308 9.00801 13.5363 8.99801ZM11.8283 13.031H9.20725L8.81025 11.528L9.42325 11.903L10.5173 9.92901L11.6123 11.903L12.2253 11.528L11.8283 13.031Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TableIcon;
