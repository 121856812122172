import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import { DEFAULT_LANG, getLngInitial } from "utils/lang"
import commonEN from "./locales/en/common.json"
import commonVI from "./locales/vi/common.json"
import commonCN from "./locales/cn/common.json"
import commonCNTrad from "./locales/cn_trad/common.json"

const supportedLangs = ["en", "vi", "cn", "cn_trad"]

export const defaultNS = "common"
export const resources = {
  en: { common: commonEN },
  vi: { common: commonVI },
  cn: { common: commonCN },
  cn_trad: { common: commonCNTrad }
} as const

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    defaultNS,
    fallbackLng: DEFAULT_LANG,
    lng: getLngInitial(supportedLangs),
    resources
  })

export default i18next
