import React, { FC, PropsWithChildren, useCallback } from "react";

import styles from "./styles.module.scss";
import { CURRENCY_KEY } from "utils";
import { useTranslation } from "react-i18next";
import { mapKeyToLang } from "./Language";
import { mapKeyToCurrency } from "utils/currency";

type IDialogMenuProps = PropsWithChildren<{
  visible: boolean;
  onHide: () => void;
  onCurrencyToggle: (v: boolean) => void;
}>;

const DialogMenu: FC<IDialogMenuProps> = ({
  visible,
  onHide,
  onCurrencyToggle,
}) => {
  const currency = localStorage.getItem(CURRENCY_KEY) || "vnd";
  const { i18n, t } = useTranslation();

  const changeLang = useCallback((lang: string) => {
    return () => {
      i18n.changeLanguage(lang);
    };
  }, []);

  const openCurrency = () => {
    onCurrencyToggle(true);
  };

  return (
    <div
      className={`${styles.menuMobile} ${visible && styles.menuMobileShow}`}
      onClick={onHide}
    >
      <ul>
        <li>
          <div className={styles.languageText}>{t("CURRENCY")}</div>
          <div className={styles.flagContainer}>
            {Object.entries(mapKeyToCurrency).map(([k, info]) => {
              return (
                <div
                  key={k}
                  className={`${styles.flag} ${
                    currency === k && styles.active
                  }`}
                  onClick={openCurrency}
                >
                  <i className={info.icon}></i>
                  <span>{info.title}</span>
                </div>
              );
            })}
          </div>
        </li>
        <li>
          <div className={styles.languageText}>{t("LANGUAGES")}</div>
          <div className={styles.flagContainer}>
            {Object.entries(mapKeyToLang).map(([k, info]) => {
              return (
                <div
                  key={k}
                  className={`${styles.flag} ${
                    i18n.language === k && styles.active
                  }`}
                  onClick={changeLang(k)}
                >
                  <i>{info.icon}</i>
                  <span>{info.title}</span>
                </div>
              );
            })}
          </div>
        </li>
      </ul>
    </div>
  );
};

export default DialogMenu;
